import {
  Box,
  Grid,
  Typography,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Dialog,
  Divider,
} from "@mui/material";
import React, { useContext } from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { useNavigate } from "react-router-dom";
import appContext from "../../context/AppContext";

import CloseIcon from "@mui/icons-material/Close";
const ReviewPractice = () => {
  const navigate = useNavigate();
  const AppContext = useContext(appContext);
  let temp = `/${AppContext.token}`;
  const [open2, setOpen2] = React.useState(false);
  const [open3, setOpen3] = React.useState(false);
  const interview = () => {
    setOpen2(true);
  };
  const handleClose2 = () => {
    setOpen2(false);
  };

  const handleClose3 = () => {
    setOpen3(false);
  };
  const startInterview = () => {
    handleClose2();
    setOpen3(true);
  };

  return (
    <Grid className="StickyContent">
      <Card
        sx={{
          padding: "15px 10px",
          boxShadow: "rgb(99 99 99 / 20%) 0px 2px 8px 0px",
          borderRadius: "8px",
        }}
      >
        <CardContent>
          <Box sx={{ width: "100%" }}>
            <Box sx={{ textAlign: "center", margin: "0px 0px 30px 0px" }}>
              <Typography
                sx={{
                  fontSize: "25px",
                  marginTop: "10px",
                  marginBottom: "25px",
                  color: "#cc0000",
                }}
              >
                <b>You have successfully done the practise session</b>
              </Typography>
              <Typography
                sx={{
                  fontSize: "25px",
                  marginTop: "10px",
                  marginBottom: "25px",
                }}
              >
                <b>You may proceed with the automated interview </b>
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Button
                style={{ "margin-left": "5px" }}
                variant="contained"
                className="secondaryBtn"
                onClick={() => {
                  navigate(temp);
                }}
              >
                Back To Home Page
              </Button>
              <Button
                style={{ "margin-left": "5px" }}
                variant="contained"
                className="primaryBtn"
                onClick={() => {
                  interview();
                  //   navigate("/FinalQuestionAnswer");
                }}
              >
                Start Automated Interview
              </Button>
            </Box>
          </Box>
        </CardContent>
      </Card>

      {/*Actual Modal to Start the Interview*/}
      <Dialog
        open={open2}
        onClose={handleClose2}
        PaperProps={{
          style: { borderRadius: 10 },
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Instructions"}
          <IconButton
            aria-label="close"
            onClick={handleClose2}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              // color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <Divider />
        <DialogContent>
          <div>
            <Typography>
              We request to do a practise session first if not done already.
            </Typography>
            <br />
            <Typography>
              Moving forward, you will be asked questions which are video /
              audio based, MCQs or descriptive. These are all time-based
              therefore please make sure you complete the answers within the
              given time.
            </Typography>
            <br />
            <Typography>
              The interview questions will appear in full screen mode, please do
              not exit full screen mode or you would be exited from the
              interview as well.
            </Typography>
            <br />
            <Typography>
              You may review your answers in the end but cannot re-submit them.
            </Typography>
          </div>
        </DialogContent>
        <DialogActions style={{ justifyContent: "right" }}>
          <Button
            onClick={startInterview}
            autoFocus
            variant="contained"
            sx={{ mr: 1 }}
            className="primaryBtn"
          >
            Continue
          </Button>
        </DialogActions>
        <br />
      </Dialog>

      <Dialog
        open={open3}
        onClose={handleClose3}
        PaperProps={{
          style: { borderRadius: 10 },
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Instructions"}
          <IconButton
            aria-label="close"
            onClick={handleClose3}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              // color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <Divider />
        <DialogContent>
          <div>
            <Typography>AI & Data Consent</Typography>

            <Typography style={{ fontSize: "15px", marginTop: "10px" }}>
              <strong>Data Retention Consent:</strong> I consent to the
              retention of my personal data for 6 months after the recruitment
              process for future job opportunities. I understand I can request
              deletion of my data at any time.
            </Typography>

            <Typography style={{ fontSize: "15px", marginTop: "10px" }}>
              <strong>Data Sharing Consent:</strong> I consent to the sharing of
              my personal data with third-party service providers involved in
              the recruitment process. I understand my data will be securely
              handled according to data protection laws.
            </Typography>

            <Typography style={{ fontSize: "15px", marginTop: "10px" }}>
              <strong>AI Analysis Consent:</strong> I consent to AI-driven
              analysis of my application data, including video, audio, and
              written assessments, as part of the recruitment process. I
              understand that I can request a human review of any AI-generated
              decisions.
            </Typography>
          </div>
        </DialogContent>
        <DialogActions style={{ justifyContent: "right" }}>
          <Button
            onClick={() => {
              navigate("/FinalQuestionAnswer");
            }}
            autoFocus
            variant="contained"
            sx={{ mr: 1 }}
            className="primaryBtn"
          >
            {/* Start the Interview */}
            Agreed & Start Interview
          </Button>
        </DialogActions>
        <br />
      </Dialog>
    </Grid>
  );
};

export default ReviewPractice;
