import React, { useContext } from "react";
import { Box, Divider, Grid, Typography } from "@mui/material";
import Link from "@mui/material/Link";
import appContext from "../../context/AppContext";
import { useLocation } from "react-router-dom";

// this is Header Component
const Header = () => {
  const AppContext = useContext(appContext);
  const { pathname } = useLocation();

  return (
    <div className="StickyHeader">
      {/* <Grid className="StickyHeader"> */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          flexWrap: "wrap",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "50px 0px 0px 0px",
          }}
        >
          {/* <Link href="/">
            <img
              src={
                AppContext?.isDarkMode
                  ? "/airec_logo_dark_mode.svg"
                  : "/airec-logo-05.webp"
              }
              style={{ width: "168px", padding: "10px 0px 0px 0px" }}
            />
          </Link> */}
        </Box>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Typography variant="p">{AppContext?.email}</Typography>
        </Box>
      </Box>
      {/* </Grid> */}
    </div>
  );
};

export default Header;
