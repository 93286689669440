import React, { useState, useEffect, useContext } from "react";
import { Box, Grid, Button, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import appContext from "../../context/AppContext";

//This is Footer Component
const Footer = () => {
  const [Element, setElement] = useState(null);
  const navigate = useNavigate();
  const [isFullScreenMode, setIsFullScreenMode] = useState(true);
  const AppContext = useContext(appContext);

  useEffect(() => {
    setElement(document.documentElement);
  }, []);

  const toggleFullscreen = () => {
    let document = window.document;
    document.body.classList.add("fullscreen-enable");

    if (
      !document.fullscreenElement &&
      !document.mozFullScreenElement &&
      !document.webkitFullscreenElement
    ) {
      // current working methods
      setIsFullScreenMode(false);
      if (document.documentElement.requestFullscreen) {
        document.documentElement.requestFullscreen();
      } else if (document.documentElement.mozRequestFullScreen) {
        document.documentElement.mozRequestFullScreen();
      } else if (document.documentElement.webkitRequestFullscreen) {
        document.documentElement.webkitRequestFullscreen();
      }
    } else {
      setIsFullScreenMode(true);
      if (document.cancelFullScreen) {
        document.cancelFullScreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen();
      }
    }

    // handle fullscreen exit
    const exitHandler = () => {
      if (
        !document.webkitIsFullScreen &&
        !document.mozFullScreen &&
        !document.msFullscreenElement
      ) {
        document.body.classList.remove("fullscreen-enable");
        setIsFullScreenMode(true);
      }
    };
    document.addEventListener("fullscreenchange", exitHandler);
    document.addEventListener("webkitfullscreenchange", exitHandler);
    document.addEventListener("mozfullscreenchange", exitHandler);
  };

  return (
    <Grid className="StickyFooter">
      <Box className="footerParent">
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
          my={1}
        >
          <Typography style={{ color: "#fff" }}>Powered by </Typography>
          <img src="/0004.png" style={{ width: "120px" }} />
        </Box>
        <Box className="footerBtn" my={1}>
          {window.location.pathname === "/PracticeInterview" && (
            <Button
              variant="outlined"
              className="tranparentBtn"
              sx={{
                marginRight: "10px",
                marginBottom: { sm: "0px", xs: "10px" },
              }}
              onClick={() => {
                navigate("/Interview");
              }}
            >
              Skip Practise
            </Button>
          )}
          <Button
            variant="outlined"
            className="tranparentBtn"
            sx={{
              marginRight: "10px",
              marginBottom: { sm: "0px", xs: "10px" },
            }}
            data-toggle="fullscreen"
            onClick={() => AppContext?.setThemeMode(!AppContext?.isDarkMode)}
          >
            {AppContext?.isDarkMode
              ? "Switch to Light Mode"
              : "Switch to Dark Mode"}
          </Button>
          {/*<Button*/}
          {/*  variant="outlined"*/}
          {/*  className="tranparentBtn"*/}
          {/*  sx={{*/}
          {/*    marginRight: "10px",*/}
          {/*    marginBottom: { sm: "0px", xs: "10px" },*/}
          {/*  }}*/}
          {/*  data-toggle="fullscreen"*/}
          {/*  onClick={toggleFullscreen}*/}
          {/*>*/}
          {/*  {isFullScreenMode ? "Full Screen" : "Exit"}*/}
          {/*</Button>*/}
          <Button
            variant="outlined"
            className="tranparentBtn"
            sx={{ marginBottom: { sm: "0px", xs: "10px" } }}
            href="https://airec.io/contact/"
            target="_blank"
          >
            Support
          </Button>
        </Box>
      </Box>
    </Grid>
  );
};

export default Footer;
